import styled from "styled-components";

import { Button } from "@/components/ui/button";
import { colourScheme } from "utils/styling/colours";

export const StyledHeroButton = styled(Button)`
    background-color: ${colourScheme.successDark};
    &:hover {
        background-color: ${colourScheme.success};
        transform: scale(1.05);
    }
    height: 3rem;
    transition: transform 0.2s;
`;
