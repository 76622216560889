"use client";

import { useState } from "react";
import { motion } from "framer-motion";
import { ArrowRight, Award, Book, Brush, Calendar, ChevronLeft, ChevronRight, Code, Coins, Crown, Download, Edit3, FileImage, Folder, Gift, Globe, Image as ImageIcon, Layers, MessageSquare, PaletteIcon, Quote, Settings, Share, Sparkles, Star, Swords, Target, ThumbsUp, TrendingUp, Trophy, UserCheck, Users, Variable, Wand, Wand2 } from "lucide-react";
import Image, { StaticImageData } from "next/image";
import Link from "next/link";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { FeaturesEasyArtGeneration, FeaturesEasyToUse, FeaturesGallery, FeaturesMonsterGenerator, FeaturesStatBlock, FeatureThemes, HeroCharacter, HeroLandscape, HeroMonster, ImageToImageHeroForge, ImageToImageStyle, LandingRobot, TestimonialBuclear, TestimonialKrackle, TestimonialLogan } from "@/images";
import { cn } from "@/lib/utils";
import { StyledHeroButton } from "./Landing.styled";
interface TestimonialProps {
  quote: string;
  author: string;
  role: string;
  image: StaticImageData;
}
interface PricingPlanProps {
  name: string;
  price: string;
  period?: string;
  description: string;
  features: string[];
  featured?: boolean;
}
interface FeatureProps {
  icon: React.ElementType;
  title: string;
  desc: string;
}
interface KeyFeatureProps {
  icon: React.ElementType;
  title: string;
  description: string;
}
const showcaseItems = [{
  title: "Elf Paladin",
  desc: "Create legendary characters",
  src: {
    HeroCharacter
  }
}, {
  title: "Legendary Beholder",
  desc: "Generate Monsters with ease",
  src: {
    HeroMonster
  }
}, {
  title: "Mirabar",
  desc: "Craft captivating landscapes in seconds",
  src: {
    HeroLandscape
  }
}];
export default function Page() {
  const [activeFeature, setActiveFeature] = useState(0);
  const [activeTestimonial, setActiveTestimonial] = useState(0);
  const testimonials: TestimonialProps[] = [{
    quote: "As a GM, Char-gen has given me the tools to really enrich my campaigns. Ive created images for player characters, NPCs, monsters and even scenes. These handouts have really helped to set the scene online or around the table.",
    author: "Logan XVII",
    role: "Game Master",
    image: TestimonialLogan
  }, {
    quote: "As someone who loves creating unique characters for my campaigns, CharGen has been a game-changer. The AI-generated art is incredible, and the ability to customize every detail means my characters look exactly how I envision them.",
    author: "Krackle Brightbeak",
    role: "D&D Player",
    image: TestimonialKrackle
  }, {
    quote: "The free daily art generations are fantastic! I love that I dont need to subscribe to get high-quality character and monster art. CharGen has made my game preparation so much easier and more enjoyable.",
    author: "Buclear",
    role: "Pathfinder Player",
    image: TestimonialBuclear
  }];
  const aiModels = [{
    name: "DALL-E",
    description: "OpenAI's advanced image generation model"
  }, {
    name: "Stable Diffusion 3.5",
    description: "Latest version of the Stable Diffusion model for high-quality image generation, with superior quality and prompt adherence, this base model is the most powerful in the Stable Diffusion family."
  }, {
    name: "Flux Pro 1.1",
    description: "The leading version of Flux.1. Flux.1 [pro] offering state-of-the-art performance image generation with top of the line prompt following, visual quality, image detail and output diversity."
  }, {
    name: "Ideogram 2.0",
    description: "Ideogram 2.0 represents a significant leap forward in text-to-image technology, offering enhanced realism, improved style control, and seamless integration"
  }, {
    name: "OmniGen V1",
    description: "OmniGen is a unified image generation model that can generate a wide range of images from multi-modal prompts. In short, it's AMAZING for image referencing!"
  }, {
    name: "Flux Dev",
    description: "FLUX.1 Dev from Black Forest Labs is a 12 billion parameter rectified flow transformer capable of generating images from text descriptions."
  }, {
    name: "Juggernaut XI",
    description: "Powerful model from RunDiffusion for high-resolution images, high-detail, realistic images and fantasy art"
  }, {
    name: "AuraFlow",
    description: "Fully open-sourced largest flow-based text-to-image generation mode from Fal. Smooth, stylized, surreal images with a soft, dreamy quality"
  }];
  const sampleImages = [{
    src: "https://charsheet-gen.s3.eu-west-2.amazonaws.com/charsheet-gen/33408dfb-9545-4786-8018-3d759f35f9e4.webp",
    alt: "Shadow Armour Female Paladin",
    model: "Stable Diffusion 3.5"
  }, {
    src: "https://charsheet-gen.s3.eu-west-2.amazonaws.com/charsheet-gen/e69057ac-0cc4-4a3f-b98e-ab1178610a25.webp",
    alt: "Facepalming Orc",
    model: "Flux Pro 1.1"
  }, {
    src: "https://charsheet-gen.s3.eu-west-2.amazonaws.com/charsheet-gen/7812e8a1-1b5f-44f8-a114-13061c44e384+(1).webp",
    alt: "Magical Sword and Skulls",
    model: "Ideogram 2.0"
  }, {
    src: "https://charsheet-gen.s3.eu-west-2.amazonaws.com/charsheet-gen/b15a48ca-b189-4aff-90c0-509c8c21391e.webp",
    alt: "Tiefling with Armour in Ruins",
    model: "OmniGen V1"
  }, {
    src: "https://charsheet-gen.s3.eu-west-2.amazonaws.com/charsheet-gen/831ec879-4aa2-40c1-8f24-859004ad1d5a.webp",
    alt: "Female Human Warrior",
    model: "Flux Dev"
  }, {
    src: "https://charsheet-gen.s3.eu-west-2.amazonaws.com/charsheet-gen/81c00551-f666-4163-84fb-9182a3e9478a.webp",
    alt: "Wizard in a Dungeon",
    model: "AuraFlow"
  }];
  const CurvedDivider = ({
    color,
    flip = false
  }: {
    color: string;
    flip?: boolean;
  }) => <div className={`w-100 ${flip ? "transform rotate-180" : ""}`} data-sentry-component="CurvedDivider" data-sentry-source-file="index.tsx">
            <svg viewBox="0 0 1440 120" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" data-sentry-element="svg" data-sentry-source-file="index.tsx">
                <path d="M0 0L60 10C120 20 240 40 360 46.7C480 53 600 47 720 36.7C840 27 960 13 1080 16.3C1200 20 1320 40 1380 50L1440 60V120H0V0Z" fill={color} data-sentry-element="path" data-sentry-source-file="index.tsx" />
            </svg>
        </div>;
  const features: FeatureProps[] = [{
    icon: Sparkles,
    title: "AI-Powered Image Generation",
    desc: "Create vivid character images with an advanced AI image generator designed for RPG and fantasy worlds."
  }, {
    icon: Brush,
    title: "Multiple Art Styles",
    desc: "Select from diverse art styles, including realistic, anime, and fantasy, for unique character creation."
  }, {
    icon: Variable,
    title: "Smart Character Prompt Builder",
    desc: "An intuitive prompt builder to create characters with the perfect look for your campaigns."
  }, {
    icon: UserCheck,
    title: "Advanced D&D Character Creator",
    desc: "Build detailed D&D characters with custom features, backstories, and unique traits."
  }, {
    icon: Layers,
    title: "Campaign Asset Management",
    desc: "Organize all your characters, D&D monsters, and scenes in structured campaign folders."
  }, {
    icon: Book,
    title: "Rich Notes for Campaigns",
    desc: "Keep in-depth notes on characters, events, and plots with rich text formatting for easy reference."
  }, {
    icon: Users,
    title: "Community and Sharing Hub",
    desc: "Share your character creations and monster designs with a community of RPG creators."
  }, {
    icon: Settings,
    title: "Customizable Image Generation",
    desc: "Control image resolution, rendering settings, and artistic effects to perfect your designs."
  }, {
    icon: Star,
    title: "NPC and Monster Maker",
    desc: "Easily create NPCs and monsters with unique attributes and backgrounds for your campaigns including statblock generation."
  }, {
    icon: Calendar,
    title: "Daily Challenges",
    desc: "Join daily challenges to unlock rewards and explore new character creation ideas."
  }, {
    icon: Globe,
    title: "Diverse World-Building",
    desc: "Build immersive worlds with landscapes, cities, and regions tailored to fantasy RPGs."
  }, {
    icon: TrendingUp,
    title: "Leaderboards & Competitions",
    desc: "Compete in community events to showcase your top characters and monster designs."
  }, {
    icon: Gift,
    title: "Rewards and Achievements",
    desc: "Earn rewards for creating and sharing characters, monsters, and campaign assets."
  }, {
    icon: Edit3,
    title: "Advanced Image Editing",
    desc: "Refine generated images with face-swapping, background edits, and upscaling features."
  }, {
    icon: Code,
    title: "Integration with D&D Beyond Character PDFs",
    desc: "Seamlessly import character sheets from popular TTRPGs and create your characters with no prompting required."
  }, {
    icon: Folder,
    title: "Project-Based Organization",
    desc: "Keep track of your characters, NPCs, and world-building assets with organized folders and tags."
  }, {
    icon: Target,
    title: "Real-Time Session Companion",
    desc: "Capture and log live session details, characters, and plot points"
  }];
  const keyFeatures: KeyFeatureProps[] = [{
    icon: Users,
    title: "4,000+",
    description: "Active Users"
  }, {
    icon: ImageIcon,
    title: "75,000+",
    description: "Images Generated"
  }, {
    icon: PaletteIcon,
    title: "70+",
    description: "Art Styles"
  }, {
    icon: Wand,
    title: "24+",
    description: "Unique AI Models"
  }];
  const pricingPlans: PricingPlanProps[] = [{
    name: "Basic",
    price: "£3.99",
    period: "per month",
    description: "For casual users",
    features: ["300 monthly gold (300 Flux.Dev Generations)", "All art styles", "All features", "Organise images with folders", "Save and Load Presets", "Enhanced Image Editing"]
  }, {
    name: "Plus",
    price: "£9.99",
    period: "per month",
    description: "For regular users",
    features: ["900 monthly gold (900 Flux.Dev Generations)", "All art styles", "All features", "Organise images with folders", "Save and Load Presets", "Enhanced Image Editing"]
  }, {
    name: "Elite",
    price: "£19.99",
    period: "per month",
    description: "For power users!",
    features: ["2000 monthly gold (2000 Regular Model Generations)", "UNLIMITED FLUX.DEV GENERATIONS", "All art styles", "All features", "Organise images with folders", "Save and Load Presets", "Enhanced Image Editing"],
    featured: true
  }, {
    name: "Ultimate",
    price: "£39.99",
    period: "per month",
    description: "For the ultimate creator!",
    features: ["4500 monthly gold (4500 Regular Model Generations)", "UNLIMITED FLUX.DEV GENERATIONS", "All art styles", "All features", "Organise images with folders", "Save and Load Presets", "Enhanced Image Editing"]
  }];
  return <div className="dark min-h-screen bg-slate-950 text-slate-100" data-sentry-component="Page" data-sentry-source-file="index.tsx">
            <main className="flex-1">
                {/* Hero Section */}
                <section className="relative pt-32 pb-24 overflow-hidden">
                    <div className="container mx-auto px-4">
                        <div className="max-w-4xl mx-auto text-center mb-16">
                            <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent">
                                Instant AI Generated Art for Your RPGs
                            </h1>
                            <p className="text-lg md:text-xl text-slate-400 mb-8 max-w-2xl mx-auto">
                                Create stunning character art for your RPGs in
                                seconds. No prompting expertise required.
                            </p>
                            <div className="flex flex-col sm:flex-row justify-center gap-4 mb-12">
                                <Link href="/generate" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                    <StyledHeroButton size="lg" data-sentry-element="StyledHeroButton" data-sentry-source-file="index.tsx">
                                        🔥 Get Started For Free
                                    </StyledHeroButton>
                                </Link>
                            </div>
                        </div>

                        {/* Hero Images Grid */}
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-5xl mx-auto">
                            {showcaseItems.map((item, index) => <div key={index} className="group relative aspect-[3/4] overflow-hidden rounded-xl bg-slate-900 transform transition-transform hover:scale-105">
                                    <Image src={Object.values(item.src)[0]} alt={item.title} fill loading="eager" priority sizes="(max-width: 768px) 100vw, 33vw" className="object-cover transition-transform duration-300 group-hover:scale-110" />
                                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                                    <div className="absolute bottom-0 left-0 right-0 p-4">
                                        <p className="text-white font-medium">
                                            {item.title}
                                        </p>
                                        <p className="text-sm text-slate-300">
                                            {item.desc}
                                        </p>
                                    </div>
                                </div>)}
                        </div>
                    </div>
                </section>

                {/* Main Feature Section */}
                <section className="relative pt-16 xl:pt-64 lg:pt-32 pb-8 bg-slate-900/50">
                    <div className="absolute top-0 left-0 right-0 z-0">
                        <CurvedDivider color="rgb(2 6 23)" flip={true} data-sentry-element="CurvedDivider" data-sentry-source-file="index.tsx" />
                    </div>
                    <div className="container mx-auto px-4">
                        <div className="max-w-6xl mx-auto">
                            <div className="grid md:grid-cols-2 gap-12 items-center">
                                <div className="relative aspect-[4/3] md:aspect-square w-full overflow-hidden rounded-xl">
                                    <Image src={LandingRobot} alt="AI-generated fantasy character artwork example" fill sizes="(max-width: 768px) 100vw, 50vw" priority loading="eager" className="object-cover transform transition-transform hover:scale-105" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                                </div>
                                <div className="prose prose-invert prose-lg max-w-none z-[1]">
                                    <h2 className="text-3xl md:text-4xl font-bold mb-6">
                                        Create Stunning Character Art with AI
                                    </h2>
                                    <p className="text-lg md:text-xl text-slate-300 leading-relaxed mb-6">
                                        CharGen makes it simple to create
                                        stunning art for your D&D characters,
                                        NPCs, and monsters with our AI character
                                        creator. Whether you are looking for a
                                        D&D monster maker, an AI RPG generator,
                                        or the best AI image generator for your
                                        fantasy campaigns, CharGen has you
                                        covered. Experience the best D&D
                                        character creator and generate
                                        captivating fantasy AI art effortlessly.
                                    </p>
                                    <div className="flex flex-wrap gap-3">
                                        {["D&D Character Creator", "Monster Maker", "NPC Creator", "Fantasy Art AI"].map(tag => <span key={tag} className="inline-flex items-center rounded-full bg-blue-900/30 px-4 py-1 text-sm text-blue-300 ring-1 ring-blue-400/20 transform transition-transform hover:scale-105">
                                                {tag}
                                            </span>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Hero CTA Banner */}
                <section className="relative py-16 bg-gradient-to-r from-blue-900 to-blue-800 overflow-hidden">
                    <div className="container mx-auto px-4 relative z-10">
                        <div className="max-w-5xl mx-auto flex flex-col md:flex-row items-center justify-between gap-6">
                            <div>
                                <h3 className="text-2xl font-bold mb-2">
                                    Ready to create your character?
                                </h3>
                                <p className="text-slate-300">
                                    Join thousands of Game Masters using CharGen
                                    today.
                                </p>
                            </div>
                            <Link href="/generate" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                <StyledHeroButton size="lg" data-sentry-element="StyledHeroButton" data-sentry-source-file="index.tsx">
                                    Start Generating for Free Now
                                </StyledHeroButton>
                            </Link>
                        </div>
                    </div>
                    <div className="absolute inset-0 bg-blue-500/10">
                        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yOS41IDMxLjVMMzEgMjguNUwzMi41IDMxLjVMMzUuNSAzM0wzMi41IDM0LjVMMzEgMzcuNUwyOS41IDM0LjVMMjYuNSAzM0wyOS41IDMxLjVaIiBmaWxsPSJjdXJyZW50Q29sb3IiIC8+Cjwvc3ZnPg==')]
                opacity-20 animate-twinkle" />
                    </div>
                </section>

                {/* Image-to-Image Section */}
                <section className="py-24 bg-slate-900/50">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl md:text-5xl font-bold text-center mb-12">
                            Upload Reference Images
                        </h2>
                        <div className="grid md:grid-cols-2 gap-12 items-center">
                            <div>
                                <h3 className="text-2xl font-bold mb-6">
                                    Style Transfer
                                </h3>
                                <p className="text-slate-300 mb-6">
                                    Upload any image and use it as a reference.
                                    Copy poses, styles, sketches, and more with
                                    CharGen&apos;s AI image referencing and
                                    style transformation.
                                </p>
                                <div className="relative aspect-[4/3] overflow-hidden rounded-xl">
                                    <Image src={ImageToImageStyle} alt="Example of style transfer: A character portrait transformed into different artistic styles" fill sizes="(max-width: 768px) 100vw, 50vw" className="object-cover" loading="lazy" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                                    <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent" />
                                    <div className="absolute bottom-4 left-4 right-4">
                                        <p className="text-white text-sm">
                                            Original image pose used as
                                            reference.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h3 className="text-2xl font-bold mb-6">
                                    HeroForge to AI Art
                                </h3>
                                <p className="text-slate-300 mb-6">
                                    Turn your HeroForge creations into stunning,
                                    AI-generated artwork. Simply upload a
                                    screenshot, and watch as CharGen brings your
                                    character to life.
                                </p>
                                <div className="relative aspect-[4/3] overflow-hidden rounded-xl">
                                    <Image src={ImageToImageHeroForge} alt="Example of HeroForge to AI art: A HeroForge screenshot transformed into a detailed character illustration" fill sizes="(max-width: 768px) 100vw, 50vw" className="object-cover" loading="lazy" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                                    <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent" />
                                    <div className="absolute bottom-4 left-4 right-4">
                                        <p className="text-white text-sm">
                                            HeroForge screenshot transformed
                                            into AI-generated character art
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-12 text-center">
                            <h3 className="text-2xl font-bold mb-6">
                                How It Works
                            </h3>
                            <div className="flex flex-col md:flex-row justify-center items-center gap-8">
                                <div className="flex items-center">
                                    <div className="bg-blue-700 rounded-full p-4">
                                        <FileImage className="h-8 w-8 text-white" data-sentry-element="FileImage" data-sentry-source-file="index.tsx" />
                                    </div>
                                    <ArrowRight className="h-6 w-6 text-blue-500 mx-4" data-sentry-element="ArrowRight" data-sentry-source-file="index.tsx" />
                                    <div className="bg-blue-700 rounded-full p-4">
                                        <Wand2 className="h-8 w-8 text-white" data-sentry-element="Wand2" data-sentry-source-file="index.tsx" />
                                    </div>
                                    <ArrowRight className="h-6 w-6 text-blue-500 mx-4" data-sentry-element="ArrowRight" data-sentry-source-file="index.tsx" />
                                    <div className="bg-blue-700 rounded-full p-4">
                                        <Download className="h-8 w-8 text-white" data-sentry-element="Download" data-sentry-source-file="index.tsx" />
                                    </div>
                                </div>
                                <p className="text-slate-300 mt-4 md:mt-0 md:ml-8">
                                    Upload your image, sketch, or whatever you
                                    want to use as a reference, choose your
                                    desired style, race, monster, character,
                                    whatever you&apos;re generating, and
                                    generate your new artwork!
                                </p>
                            </div>
                        </div>
                        <div className="mt-12 text-center">
                            <Link href="/generate" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                <StyledHeroButton size="lg" data-sentry-element="StyledHeroButton" data-sentry-source-file="index.tsx">
                                    Try it for Free now!
                                </StyledHeroButton>
                            </Link>
                        </div>
                    </div>
                </section>

                <section className="py-24 bg-gradient-to-b from-slate-900 to-slate-950">
                    <div className="container mx-auto px-4">
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                            {/* Easy-to-Use Interface */}
                            <Card className="bg-slate-900 border-slate-700 py-4" data-sentry-element="Card" data-sentry-source-file="index.tsx">
                                <CardContent className="p-6" data-sentry-element="CardContent" data-sentry-source-file="index.tsx">
                                    <div className="aspect-video relative rounded-lg overflow-hidden mb-4">
                                        <Image src={FeaturesEasyArtGeneration} alt="AI-generated monsters watercolour ghosts with appearance and description information" fill className="object-cover" loading="lazy" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                                    </div>
                                    <h3 className="text-xl font-bold mb-2">
                                        Easy-to-Use Interface
                                    </h3>
                                    <p className="text-sm text-slate-300 mb-4">
                                        No need for complex prompts or artistic
                                        knowledge. With our easy-to-use
                                        interface, creating AI-generated art for
                                        your campaigns is as simple as a few
                                        clicks. Utilize our AI D&D character
                                        generator and monster AI generator to
                                        bring your fantasy worlds to life with
                                        the best fantasy image generator
                                        available.
                                    </p>
                                    <Link href="/generate" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                        <Button variant="outline" size="sm" className="w-full bg-emerald-700 hover:bg-emerald-500 transform transition-transform hover:scale-105" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                            Try it for Free now!
                                        </Button>
                                    </Link>
                                </CardContent>
                            </Card>

                            {/* Effortless AI Generated Fantasy Art */}
                            <Card className="bg-slate-900 border-slate-700 py-4" data-sentry-element="Card" data-sentry-source-file="index.tsx">
                                <CardContent className="p-6" data-sentry-element="CardContent" data-sentry-source-file="index.tsx">
                                    <div className="aspect-video relative rounded-lg overflow-hidden mb-4">
                                        <Image src={FeaturesEasyToUse} alt="Examples of AI-generated fantasy art for characters, NPCs, and monsters" fill className="object-cover" loading="lazy" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                                    </div>
                                    <h3 className="text-xl font-bold mb-2">
                                        Effortless AI Generated Fantasy Art
                                    </h3>
                                    <p className="text-sm text-slate-300 mb-4">
                                        Turn your RPG ideas into stunning,
                                        detailed fantasy art with AI. CharGen
                                        makes it simple to generate art for
                                        characters, NPCs, monsters, and more,
                                        without needing any artistic skills. Use
                                        our AI photo generator and free AI image
                                        generator to bring your Dungeons and
                                        Dragons campaigns to life.
                                    </p>
                                    <Link href="/generate" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                        <Button variant="outline" size="sm" className="w-full bg-emerald-700 hover:bg-emerald-500 transform transition-transform hover:scale-105" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                            Try it for Free now!
                                        </Button>
                                    </Link>
                                </CardContent>
                            </Card>

                            {/* User Gallery */}
                            <Card className="bg-slate-900 border-slate-700 py-4" data-sentry-element="Card" data-sentry-source-file="index.tsx">
                                <CardContent className="p-6" data-sentry-element="CardContent" data-sentry-source-file="index.tsx">
                                    <div className="aspect-video relative rounded-lg overflow-hidden mb-4">
                                        <Image src={FeaturesGallery} alt="Gallery of AI-generated NPCs, characters, and monsters created by users" fill className="object-cover" loading="lazy" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                                    </div>
                                    <h3 className="text-xl font-bold mb-2">
                                        Gallery of AI-Generated NPCs,
                                        Characters, and Monsters Created by
                                        Users
                                    </h3>
                                    <p className="text-sm text-slate-300 mb-4">
                                        Showcase Your Creations in the User
                                        Gallery. Share your artwork with the
                                        CharGen community through our user
                                        gallery. Explore and vote on others
                                        creations, see the settings and prompts
                                        they used, and get inspired by the
                                        diverse array of AI generated images and
                                        fantasy art from fellow users.
                                    </p>
                                    <Link href="/gallery" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                        <Button variant="outline" size="sm" className="w-full bg-emerald-700 hover:bg-emerald-500 transform transition-transform hover:scale-105" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                            Visit the Gallery
                                        </Button>
                                    </Link>
                                </CardContent>
                            </Card>

                            {/* Enhanced Monster Creator */}
                            <Card className="bg-slate-900 border-slate-700 py-4" data-sentry-element="Card" data-sentry-source-file="index.tsx">
                                <CardContent className="p-6" data-sentry-element="CardContent" data-sentry-source-file="index.tsx">
                                    <div className="aspect-video relative rounded-lg overflow-hidden mb-4">
                                        <Image src={FeaturesStatBlock} alt="Enhanced Monster Creator with Stat Block Generator for D&D Monsters" fill className="object-cover" loading="lazy" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                                    </div>
                                    <h3 className="text-xl font-bold mb-2">
                                        Enhanced Monster Creator with our Stat
                                        Block Generator
                                    </h3>
                                    <p className="text-sm text-slate-300 mb-4">
                                        Effortlessly create monster stat blocks
                                        with our advanced generator. Simply
                                        provide a CR level, and you can generate
                                        detailed stat blocks within seconds,
                                        perfectly tailored to your created
                                        monsters. This feature is designed to
                                        complement our D&D monster maker and AI
                                        monster generator, streamlining your
                                        monster creation process and enhancing
                                        your game preparation. Experience
                                        seamless integration of accurate and
                                        customized monster stats into your D&D
                                        campaigns with just a few clicks.
                                    </p>
                                    <Link href="/generate" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                        <Button variant="outline" size="sm" className="w-full bg-emerald-700 hover:bg-emerald-500 transform transition-transform hover:scale-105" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                            Create a Monster!
                                        </Button>
                                    </Link>
                                </CardContent>
                            </Card>

                            {/* Themes and Art Styles */}
                            <Card className="bg-slate-900 border-slate-700 py-4" data-sentry-element="Card" data-sentry-source-file="index.tsx">
                                <CardContent className="p-6" data-sentry-element="CardContent" data-sentry-source-file="index.tsx">
                                    <div className="aspect-video relative rounded-lg overflow-hidden mb-4">
                                        <Image src={FeatureThemes} alt="Dropdown menu of themes and art styles available in CharGen for AI art generation" fill className="object-cover" loading="lazy" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                                    </div>
                                    <h3 className="text-xl font-bold mb-2">
                                        Hundreds of Themes and Styles
                                    </h3>
                                    <p className="text-sm text-slate-300 mb-4">
                                        Choose from hundreds of themes and art
                                        styles to match your campaign aesthetic.
                                        Whether you prefer dark and gritty or
                                        bright and whimsical, CharGen has the
                                        right style to bring your RPG world to
                                        life with our AI image creator. Enjoy a
                                        large selection of generation models
                                        including Stable Diffusion SDXL, User
                                        Created Models, DallE3, and now even
                                        introducing the new Flux models such as
                                        Flux.Pro and more to customize your
                                        fantasy art even further.
                                    </p>
                                    <Link href="/generate" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                        <Button variant="outline" size="sm" className="w-full bg-emerald-700 hover:bg-emerald-500 transform transition-transform hover:scale-105" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                            Try it for Free now!
                                        </Button>
                                    </Link>
                                </CardContent>
                            </Card>

                            {/* Diverse Races, Classes, and Monster Types */}
                            <Card className="bg-slate-900 border-slate-700 py-4" data-sentry-element="Card" data-sentry-source-file="index.tsx">
                                <CardContent className="p-6" data-sentry-element="CardContent" data-sentry-source-file="index.tsx">
                                    <div className="aspect-video relative rounded-lg overflow-hidden mb-4">
                                        <Image src={FeaturesMonsterGenerator} alt="CharGen AI-generated Monsters showing a Gelatinous Cube and race and monster generation options" fill className="object-cover" loading="lazy" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                                    </div>
                                    <h3 className="text-xl font-bold mb-2">
                                        Diverse Characters & Monsters
                                    </h3>
                                    <p className="text-sm text-slate-300 mb-4">
                                        Create art for any character, class, or
                                        creature with our extensive library of
                                        options. From classic fantasy races to
                                        unique monster types, CharGen offers a
                                        wide range of choices to fit any
                                        campaign setting. Experience the best
                                        D&D character maker and dungeons and
                                        dragons character creator available.
                                    </p>
                                    <Link href="/generate" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                        <Button variant="outline" size="sm" className="w-full bg-emerald-700 hover:bg-emerald-500 transform transition-transform hover:scale-105" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                            Try it for Free now!
                                        </Button>
                                    </Link>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </section>

                {/* Features Section */}
                <section id="features" className="py-24 relative">
                    <div className="container mx-auto px-4">
                        <div className="max-w-3xl mx-auto text-center mb-16">
                            <h2 className="text-3xl md:text-5xl font-bold mb-6">
                                Everything You Need
                            </h2>
                            <p className="text-lg text-slate-400">
                                Powerful features to bring your characters to
                                life
                            </p>
                        </div>

                        <div className="grid md:grid-cols-3 gap-8  max-w-6xl mx-auto">
                            {features.map((feature, i) => <Card key={i} className={cn("bg-slate-900/50 border-slate-800 backdrop-blur-sm cursor-pointer transform transition-all duration-300 py-4", activeFeature === i ? "scale-105 border-blue-500" : "hover:scale-105")} onClick={() => setActiveFeature(i)}>
                                    <CardContent className="p-6">
                                        <feature.icon className={cn("h-12 w-12 mb-4 transition-colors duration-300", activeFeature === i ? "text-blue-400" : "text-slate-400")} />
                                        <h3 className="text-lg font-bold mb-2">
                                            {feature.title}
                                        </h3>
                                        <p className="text-slate-400">
                                            {feature.desc}
                                        </p>
                                    </CardContent>
                                </Card>)}
                        </div>
                    </div>
                </section>

                {/* Key Features Section */}
                <section id="key-features" className="relative py-16 xl:py-64 lg:py-32 bg-slate-900/50">
                    <div className="absolute top-0 left-0 right-0">
                        <CurvedDivider color="rgb(2 6 23)" flip={true} data-sentry-element="CurvedDivider" data-sentry-source-file="index.tsx" />
                    </div>
                    <div className="container mx-auto px-4">
                        <div className="max-w-3xl mx-auto text-center mb-16">
                            <h2 className="text-3xl md:text-5xl font-bold mb-6">
                                Why Choose CharGen?
                            </h2>
                            <p className="text-lg text-slate-400">
                                Discover the power and versatility of our
                                AI-driven character generation platform
                            </p>
                        </div>

                        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 max-w-5xl mx-auto">
                            {keyFeatures.map((feature, index) => <Card key={index} className="bg-slate-800/50 border-slate-700 py-6">
                                    <CardContent className="p-6 flex flex-col items-center text-center">
                                        <feature.icon className="h-12 w-12 mb-4 text-blue-400" />
                                        <h3 className="text-2xl font-bold mb-2">
                                            {feature.title}
                                        </h3>
                                        <p className="text-slate-400">
                                            {feature.description}
                                        </p>
                                    </CardContent>
                                </Card>)}
                        </div>
                    </div>
                </section>

                <section className="py-24 bg-gradient-to-b from-black to-slate-900/50">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl md:text-5xl font-bold text-center mb-6">
                            Share and Earn Rewards
                        </h2>
                        <p className="text-lg text-slate-300 text-center mb-12 max-w-3xl mx-auto">
                            Join our vibrant community, showcase your creations,
                            and unlock amazing rewards!
                        </p>
                        <div className="grid md:grid-cols-3 gap-8">
                            {/* Share Your CharGens */}
                            <Card className="bg-slate-900 border-slate-800 hover:border-blue-500/50 transition-colors py-4" data-sentry-element="Card" data-sentry-source-file="index.tsx">
                                <CardContent className="p-6" data-sentry-element="CardContent" data-sentry-source-file="index.tsx">
                                    <div className="flex items-center space-x-4 mb-6">
                                        <div className="bg-blue-500/10 p-3 rounded-full">
                                            <Share className="h-8 w-8 text-blue-400" data-sentry-element="Share" data-sentry-source-file="index.tsx" />
                                        </div>
                                        <div>
                                            <h3 className="text-xl font-bold text-blue-400">
                                                Share Your CharGens
                                            </h3>
                                            <p className="text-sm text-slate-400">
                                                Showcase your creations
                                            </p>
                                        </div>
                                    </div>
                                    <ul className="space-y-3 mb-6">
                                        <li className="flex items-center text-slate-300">
                                            <ImageIcon className="h-5 w-5 text-blue-400 mr-3 flex-shrink-0" data-sentry-element="ImageIcon" data-sentry-source-file="index.tsx" />
                                            <span>
                                                Share images and descriptions
                                            </span>
                                        </li>
                                        <li className="flex items-center text-slate-300">
                                            <MessageSquare className="h-5 w-5 text-blue-400 mr-3 flex-shrink-0" data-sentry-element="MessageSquare" data-sentry-source-file="index.tsx" />
                                            <span>
                                                Engage with the community
                                            </span>
                                        </li>
                                        <li className="flex items-center text-slate-300">
                                            <ThumbsUp className="h-5 w-5 text-blue-400 mr-3 flex-shrink-0" data-sentry-element="ThumbsUp" data-sentry-source-file="index.tsx" />
                                            <span>
                                                Get feedback and appreciation
                                            </span>
                                        </li>
                                    </ul>
                                    <Link href="/generate" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                        <Button className="w-full bg-blue-600 hover:bg-blue-700 text-white" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                            Generate and Start Sharing
                                        </Button>
                                    </Link>
                                </CardContent>
                            </Card>

                            {/* Social Media Rewards */}
                            <Card className="bg-slate-900 border-slate-800 hover:border-yellow-500/50 transition-colors py-4" data-sentry-element="Card" data-sentry-source-file="index.tsx">
                                <CardContent className="p-6" data-sentry-element="CardContent" data-sentry-source-file="index.tsx">
                                    <div className="flex items-center space-x-4 mb-6">
                                        <div className="bg-yellow-500/10 p-3 rounded-full">
                                            <Gift className="h-8 w-8 text-yellow-400" data-sentry-element="Gift" data-sentry-source-file="index.tsx" />
                                        </div>
                                        <div>
                                            <h3 className="text-xl font-bold text-yellow-400">
                                                Earn Free Gold
                                            </h3>
                                            <p className="text-sm text-slate-400">
                                                Share and reap rewards
                                            </p>
                                        </div>
                                    </div>
                                    <ul className="space-y-3 mb-6">
                                        <li className="flex items-center text-slate-300">
                                            <Coins className="h-5 w-5 text-yellow-400 mr-3 flex-shrink-0" data-sentry-element="Coins" data-sentry-source-file="index.tsx" />
                                            <span>
                                                Earn gold for each share
                                            </span>
                                        </li>
                                        <li className="flex items-center text-slate-300">
                                            <Sparkles className="h-5 w-5 text-yellow-400 mr-3 flex-shrink-0" data-sentry-element="Sparkles" data-sentry-source-file="index.tsx" />
                                            <span>Unlock exclusive themes</span>
                                        </li>
                                        <li className="flex items-center text-slate-300">
                                            <Wand2 className="h-5 w-5 text-yellow-400 mr-3 flex-shrink-0" data-sentry-element="Wand2" data-sentry-source-file="index.tsx" />
                                            <span>
                                                Access advanced features
                                            </span>
                                        </li>
                                    </ul>
                                    <Link href="/gallery" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                        <Button className="w-full bg-yellow-600 hover:bg-yellow-700 text-white" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                            Share and Earn
                                        </Button>
                                    </Link>
                                </CardContent>
                            </Card>

                            {/* Monthly Competition */}
                            <Card className="bg-slate-900 border-slate-800 hover:border-green-500/50 transition-colors py-4" data-sentry-element="Card" data-sentry-source-file="index.tsx">
                                <CardContent className="p-6" data-sentry-element="CardContent" data-sentry-source-file="index.tsx">
                                    <div className="flex items-center space-x-4 mb-6">
                                        <div className="bg-green-500/10 p-3 rounded-full">
                                            <Trophy className="h-8 w-8 text-green-400" data-sentry-element="Trophy" data-sentry-source-file="index.tsx" />
                                        </div>
                                        <div>
                                            <h3 className="text-xl font-bold text-green-400">
                                                Monthly Prizes
                                            </h3>
                                            <p className="text-sm text-slate-400">
                                                Compete and win
                                            </p>
                                        </div>
                                    </div>
                                    <ul className="space-y-3 mb-6">
                                        <li className="flex items-center text-slate-300">
                                            <Award className="h-5 w-5 text-green-400 mr-3 flex-shrink-0" data-sentry-element="Award" data-sentry-source-file="index.tsx" />
                                            <span>Win free Gold</span>
                                        </li>
                                        <li className="flex items-center text-slate-300">
                                            <Crown className="h-5 w-5 text-green-400 mr-3 flex-shrink-0" data-sentry-element="Crown" data-sentry-source-file="index.tsx" />
                                            <span>Top the leaderboard</span>
                                        </li>
                                        <li className="flex items-center text-slate-300">
                                            <TrendingUp className="h-5 w-5 text-green-400 mr-3 flex-shrink-0" data-sentry-element="TrendingUp" data-sentry-source-file="index.tsx" />
                                            <span>Unlock premium features</span>
                                        </li>
                                    </ul>
                                    <Link href="/competitions" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                        <Button className="w-full bg-green-600 hover:bg-green-700 text-white" data-sentry-element="Button" data-sentry-source-file="index.tsx">
                                            Join Competition
                                        </Button>
                                    </Link>
                                </CardContent>
                            </Card>
                        </div>
                    </div>
                </section>

                {/* Hero CTA Banner */}
                <section className="relative py-16 bg-gradient-to-r from-blue-900 to-blue-800 overflow-hidden">
                    <div className="container mx-auto px-4 relative z-10">
                        <div className="max-w-5xl mx-auto flex flex-col md:flex-row items-center justify-between gap-6">
                            <div>
                                <h3 className="text-2xl font-bold mb-2">
                                    Ready to create your character?
                                </h3>
                                <p className="text-slate-300">
                                    Join thousands of Game Masters using CharGen
                                    today.
                                </p>
                            </div>
                            <Link href="/generate" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                <StyledHeroButton size="lg" data-sentry-element="StyledHeroButton" data-sentry-source-file="index.tsx">
                                    Start Creating for Free Now!
                                </StyledHeroButton>
                            </Link>
                        </div>
                    </div>
                    <div className="absolute inset-0 bg-blue-500/10">
                        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yOS41IDMxLjVMMzEgMjguNUwzMi41IDMxLjVMMzUuNSAzM0wzMi41IDM0LjVMMzEgMzcuNUwyOS41IDM0LjVMMjYuNSAzM0wyOS41IDMxLjVaIiBmaWxsPSJjdXJyZW50Q29sb3IiIC8+Cjwvc3ZnPg==')]
                opacity-20 animate-twinkle" />
                    </div>
                </section>

                {/* AI Models Showcase */}
                <section id="ai-models" className="py-24 px-4 sm:px-6 md:px-8 relative bg-slate-900">
                    <div className="container mx-auto max-w-7xl">
                        <motion.h2 className="text-4xl font-bold mb-12 text-center text-white" initial={{
            opacity: 0,
            y: 20
          }} whileInView={{
            opacity: 1,
            y: 0
          }} viewport={{
            once: true
          }} transition={{
            duration: 0.8
          }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                            Powerful AI Models at Your Fingertips
                        </motion.h2>
                        <Tabs defaultValue="models" className="max-w-3xl mx-auto bg-muted-black" data-sentry-element="Tabs" data-sentry-source-file="index.tsx">
                            <TabsList className="grid w-full grid-cols-2 mb-8 bg-transparent" data-sentry-element="TabsList" data-sentry-source-file="index.tsx">
                                <TabsTrigger value="models" className="px-4 py-2 font-semibold text-gray-200 bg-blue-600 rounded-l-lg hover:bg-blue-700 focus:bg-blue-100 transition-colors duration-200" data-sentry-element="TabsTrigger" data-sentry-source-file="index.tsx">
                                    AI Models
                                </TabsTrigger>
                                <TabsTrigger value="samples" className="px-4 py-2 font-semibold text-gray-200 bg-blue-600 rounded-l-lg hover:bg-blue-700 focus:bg-blue-500 transition-colors duration-200" data-sentry-element="TabsTrigger" data-sentry-source-file="index.tsx">
                                    Sample Outputs
                                </TabsTrigger>
                            </TabsList>
                            <TabsContent value="models" data-sentry-element="TabsContent" data-sentry-source-file="index.tsx">
                                <Card className="bg-cardBg border-none py-4" data-sentry-element="Card" data-sentry-source-file="index.tsx">
                                    <CardContent className="p-6" data-sentry-element="CardContent" data-sentry-source-file="index.tsx">
                                        <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                            {aiModels.map((model, index) => <motion.li key={index} className="flex items-start space-x-3" initial={{
                      opacity: 0,
                      x: -20
                    }} whileInView={{
                      opacity: 1,
                      x: 0
                    }} viewport={{
                      once: true
                    }} transition={{
                      duration: 0.5,
                      delay: index * 0.1
                    }}>
                                                    <Wand2 className="w-6 h-6 text-secondary flex-shrink-0 mt-1" />
                                                    <div>
                                                        <h3 className="font-semibold text-lg text-white">
                                                            {model.name}
                                                        </h3>
                                                        <p className="text-gray-300 text-sm">
                                                            {model.description}
                                                        </p>
                                                    </div>
                                                </motion.li>)}
                                        </ul>
                                    </CardContent>
                                </Card>
                            </TabsContent>
                            <TabsContent value="samples" data-sentry-element="TabsContent" data-sentry-source-file="index.tsx">
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                                    {sampleImages.map((image, index) => <motion.div key={index} initial={{
                  opacity: 0,
                  scale: 0.8
                }} whileInView={{
                  opacity: 1,
                  scale: 1
                }} viewport={{
                  once: true
                }} transition={{
                  duration: 0.5,
                  delay: index * 0.1
                }}>
                                            <Card className="bg-cardBg border-none overflow-hidden relative group" style={{
                    width: "200px",
                    height: "200px"
                  }}>
                                                <CardContent className="p-0 relative h-full w-full">
                                                    <div className="absolute inset-0">
                                                        <Image src={image.src} alt={image.alt} layout="fill" objectFit="cover" className="w-full h-full" loading="lazy" />
                                                    </div>
                                                    <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-70 transition-opacity duration-300 flex items-center justify-center">
                                                        <p className="text-white text-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                            {image.model}
                                                        </p>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </motion.div>)}
                                </div>
                            </TabsContent>
                        </Tabs>
                    </div>
                </section>

                {/* Community and Rewards Section */}
                <section id="community" className="relative py-16 xl:py-32 lg:py-16 px-4 sm:px-6 md:px-8 bg-slate-950">
                    <div className="container pb-24 mx-auto max-w-7xl">
                        <motion.h2 className="text-4xl font-bold mb-12 text-center text-white" initial={{
            opacity: 0,
            y: 20
          }} whileInView={{
            opacity: 1,
            y: 0
          }} viewport={{
            once: true
          }} transition={{
            duration: 0.8
          }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                            Join the CharGen Community
                        </motion.h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                            <motion.div initial={{
              opacity: 0,
              x: -20
            }} whileInView={{
              opacity: 1,
              x: 0
            }} viewport={{
              once: true
            }} transition={{
              duration: 0.8
            }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                                <h3 className="text-2xl font-semibold mb-6 text-white">
                                    Engage, Create, and Earn
                                </h3>
                                <ul className="space-y-4">
                                    {[{
                  icon: <Trophy className="w-6 h-6 mr-3 text-secondary flex-shrink-0" />,
                  text: "Participate in daily competitions and win exclusive rewards"
                }, {
                  icon: <Star className="w-6 h-6 mr-3 text-secondary flex-shrink-0" />,
                  text: "Complete daily achievements and tasks to earn free gold and image generations"
                }, {
                  icon: <Globe className="w-6 h-6 mr-3 text-secondary flex-shrink-0" />,
                  text: "Showcase your creations in our public gallery"
                }, {
                  icon: <Users className="w-6 h-6 mr-3 text-secondary flex-shrink-0" />,
                  text: "Connect with fellow artists and RPG enthusiasts"
                }].map((item, index) => <motion.li key={index} className="flex items-start" initial={{
                  opacity: 0,
                  x: -20
                }} whileInView={{
                  opacity: 1,
                  x: 0
                }} viewport={{
                  once: true
                }} transition={{
                  duration: 0.5,
                  delay: index * 0.1
                }}>
                                            {item.icon}
                                            <span className="text-gray-300">
                                                {item.text}
                                            </span>
                                        </motion.li>)}
                                </ul>
                            </motion.div>
                            <motion.div initial={{
              opacity: 0,
              x: 20
            }} whileInView={{
              opacity: 1,
              x: 0
            }} viewport={{
              once: true
            }} transition={{
              duration: 0.8
            }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                                <Card className="bg-cardBg border-none py-4" data-sentry-element="Card" data-sentry-source-file="index.tsx">
                                    <CardContent className="p-6" data-sentry-element="CardContent" data-sentry-source-file="index.tsx">
                                        <h3 className="text-2xl font-semibold mb-6 text-white">
                                            Daily Rewards
                                        </h3>
                                        <div className="space-y-4">
                                            {[{
                      action: "Login Bonus",
                      reward: "+10 Gold"
                    }, {
                      action: "Comment on an Image",
                      reward: "+5 Gold"
                    }, {
                      action: "Win a Competition",
                      reward: "+30 Gold"
                    }].map((item, index) => <motion.div key={index} className="flex justify-between items-center" initial={{
                      opacity: 0,
                      y: 10
                    }} whileInView={{
                      opacity: 1,
                      y: 0
                    }} viewport={{
                      once: true
                    }} transition={{
                      duration: 0.5,
                      delay: index * 0.1
                    }}>
                                                    <span className="text-gray-300">
                                                        {item.action}
                                                    </span>
                                                    <span className="font-bold text-secondary">
                                                        {item.reward}
                                                    </span>
                                                </motion.div>)}
                                        </div>
                                    </CardContent>
                                </Card>
                            </motion.div>
                        </div>
                    </div>
                    <div className="absolute bottom-0 left-0 right-0">
                        <CurvedDivider color="rgb(15 23 42)" flip={false} data-sentry-element="CurvedDivider" data-sentry-source-file="index.tsx" />
                    </div>
                </section>

                {/* Testimonials Section */}
                <section className="py-24 bg-slate-900">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl md:text-5xl font-bold text-center mb-12">
                            What Our Users Say
                        </h2>
                        <div className="relative max-w-4xl mx-auto">
                            <div className="overflow-hidden">
                                <div className="flex transition-transform duration-300 ease-in-out" style={{
                transform: `translateX(-${activeTestimonial * 100}%)`
              }}>
                                    {testimonials.map((testimonial, index) => <div key={index} className="w-full flex-shrink-0 px-4">
                                            <Card className="bg-slate-800 border-slate-700 p-4">
                                                <CardContent className="p-6">
                                                    <Quote className="h-8 w-8 text-blue-400 mb-4" />
                                                    <p className="text-lg mb-4">
                                                        {testimonial.quote}
                                                    </p>
                                                    <div className="flex items-center">
                                                        <Image src={testimonial.image} alt={testimonial.author} width={50} height={50} className="rounded-full mr-4" loading="lazy" />
                                                        <div>
                                                            <p className="font-bold">
                                                                {testimonial.author}
                                                            </p>
                                                            <p className="text-sm text-slate-400">
                                                                {testimonial.role}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </div>)}
                                </div>
                            </div>
                            <button onClick={() => setActiveTestimonial(prev => prev > 0 ? prev - 1 : testimonials.length - 1)} className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-1/2 bg-blue-500 rounded-full p-2 text-white" aria-label="Previous testimonial">
                                <ChevronLeft className="h-6 w-6" data-sentry-element="ChevronLeft" data-sentry-source-file="index.tsx" />
                            </button>
                            <button onClick={() => setActiveTestimonial(prev => prev < testimonials.length - 1 ? prev + 1 : 0)} className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-1/2 bg-blue-500 rounded-full p-2 text-white" aria-label="Next testimonial">
                                <ChevronRight className="h-6 w-6" data-sentry-element="ChevronRight" data-sentry-source-file="index.tsx" />
                            </button>
                        </div>
                    </div>
                </section>

                {/* How It Works Section */}
                <section className="py-24 bg-slate-900/50">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl md:text-5xl font-bold text-center mb-12">
                            How CharGen Works
                        </h2>
                        <div className="grid md:grid-cols-3 gap-8">
                            {[{
              icon: Wand2,
              title: "Describe Your Character",
              description: "Use our intuitive interface to describe your character's appearance, personality, and background. Select from dropdown options or type your own descriptions."
            }, {
              icon: Sparkles,
              title: "AI Generation",
              description: "Our advanced AI models process your description and generate stunning, unique character artwork in literal seconds."
            }, {
              icon: Download,
              title: "Download & Use",
              description: "Download your character art in various formats and resolutions, ready for your game."
            }].map((step, index) => <div key={index} className="flex flex-col items-center text-center">
                                    <div className="bg-blue-500 rounded-full p-4 mb-4">
                                        <step.icon className="h-8 w-8 text-white" />
                                    </div>
                                    <h3 className="text-xl font-bold mb-2">
                                        {step.title}
                                    </h3>
                                    <p className="text-slate-400">
                                        {step.description}
                                    </p>
                                </div>)}
                        </div>
                    </div>
                </section>

                {/* Hero CTA Banner */}
                <section className="py-16 bg-gradient-to-r from-blue-900 to-blue-800 relative overflow-hidden">
                    <div className="container mx-auto px-4 relative z-10">
                        <div className="max-w-5xl mx-auto flex flex-col md:flex-row items-center justify-between gap-6">
                            <div>
                                <h3 className="text-2xl font-bold mb-2">
                                    Ready to create your character?
                                </h3>
                                <p className="text-slate-300">
                                    Join thousands of Game Masters using CharGen
                                    today.
                                </p>
                            </div>
                            <Link href="/generate" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                <StyledHeroButton size="lg" data-sentry-element="StyledHeroButton" data-sentry-source-file="index.tsx">
                                    Start Generating for Free Now!
                                </StyledHeroButton>
                            </Link>
                        </div>
                    </div>
                    <div className="absolute inset-0 bg-blue-500/10">
                        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjAiIGhlaWdodD0iNjAiIHZpZXdCb3g9IjAgMCA2MCA2MCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0yOS41IDMxLjVMMzEgMjguNUwzMi41IDMxLjVMMzUuNSAzM0wzMi41IDM0LjVMMzEgMzcuNUwyOS41IDM0LjVMMjYuNSAzM0wyOS41IDMxLjVaIiBmaWxsPSJjdXJyZW50Q29sb3IiIC8+Cjwvc3ZnPg==')]
                opacity-20 animate-twinkle" />
                    </div>
                </section>

                {/* Pricing Section */}
                <section id="pricing" className="py-24 relative">
                    <div className="container mx-auto px-4">
                        <div className="max-w-3xl mx-auto text-center mb-16">
                            <h2 className="text-3xl md:text-5xl font-bold mb-6">
                                Simple, Transparent Pricing
                            </h2>
                            <p className="text-lg text-slate-400">
                                Choose the plan that&apos;s right for you
                            </p>
                        </div>

                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-8 max-w-8xl mx-auto">
                            {pricingPlans.map((plan, i) => <div key={i} className={cn("relative flex", plan.featured && "md:-mt-8")}>
                                    <Card className={cn("flex flex-col w-full bg-slate-900/50 border-slate-800 backdrop-blur-sm py-4", plan.featured && "border-blue-500")}>
                                        <CardContent className="p-6">
                                            <div className="flex flex-col items-center text-center">
                                                <h3 className="text-2xl font-bold mb-2">
                                                    {plan.name}
                                                </h3>
                                                <p className="text-slate-400 mb-6">
                                                    {plan.description}
                                                </p>
                                                <div className="mb-6">
                                                    <span className="text-4xl font-bold">
                                                        {plan.price}
                                                    </span>
                                                    {plan.period && <span className="text-slate-400 ml-2">
                                                            {plan.period}
                                                        </span>}
                                                </div>
                                                <ul className="space-y-4 mb-8 text-left w-full">
                                                    {plan.features.map((feature, j) => <li key={j} className="flex items-center gap-3">
                                                                <Swords className="h-5 w-5 text-blue-400 flex-shrink-0" />
                                                                <span className="text-slate-300">
                                                                    {feature}
                                                                </span>
                                                            </li>)}
                                                </ul>
                                                <StyledHeroButton className="w-full">
                                                    {plan.price === "Free" ? "Get Started" : "Subscribe Now"}
                                                </StyledHeroButton>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>)}
                        </div>
                    </div>
                </section>

                {/* FAQ Section */}
                <section id="faq" className="py-24 px-4 sm:px-6 md:px-8 relative bg-slate-900/50">
                    <div className="container mx-auto max-w-7xl">
                        <motion.h2 className="text-4xl font-bold mb-12 text-center text-white" initial={{
            opacity: 0,
            y: 20
          }} whileInView={{
            opacity: 1,
            y: 0
          }} viewport={{
            once: true
          }} transition={{
            duration: 0.8
          }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                            Frequently Asked Questions
                        </motion.h2>
                        <Accordion type="single" collapsible className="w-full max-w-2xl mx-auto" data-sentry-element="Accordion" data-sentry-source-file="index.tsx">
                            {[{
              question: "Is CharGen really free to use?",
              answer: "Yes, CharGen is free to use! You can create amazing artwork without any upfront cost. We offer premium features and additional credits for those who want to enhance their experience."
            }, {
              question: "How does the gold system work?",
              answer: "You earn gold by participating in daily activities, winning competitions, and completing achievements. This gold can be used to generate more images or access premium features. Alternately you can purchase a subscription package or one-off gold amount."
            }, {
              question: "How can I participate in competitions?",
              answer: "Competitions are held daily. Simply create artwork based on the competition theme and submit it through the CharGen platform. Winners are chosen by community votes and our panel of judges."
            }, {
              question: "Are the AI models regularly updated?",
              answer: "Yes, we continuously update our AI models to ensure you have access to the latest advancements in image generation technology. We also regularly add new models to expand creative possibilities."
            }, {
              question: "Can I earn gold by referring friends?",
              answer: "Yes, you can earn gold by referring friends to CharGen. Simply share your referral link and earn gold when your friends sign up! Referral Codes are generated as part of sharing the image, so you can share images and get free gold just by people clicking on the link!"
            }].map((item, index) => <motion.div key={index} initial={{
              opacity: 0,
              y: 10
            }} whileInView={{
              opacity: 1,
              y: 0
            }} viewport={{
              once: true
            }} transition={{
              duration: 0.5,
              delay: index * 0.1
            }}>
                                    <AccordionItem value={`item-${index}`} className="border-b border-gray-700">
                                        <AccordionTrigger className="text-white hover:text-secondary">
                                            {item.question}
                                        </AccordionTrigger>
                                        <AccordionContent className="text-gray-300">
                                            {item.answer}
                                        </AccordionContent>
                                    </AccordionItem>
                                </motion.div>)}
                        </Accordion>
                    </div>
                </section>

                {/* Final CTA Section */}
                <section className="pb-20 pt-64 px-4 sm:px-6 md:px-8 relative">
                    <div className="absolute top-0 left-0 right-0">
                        <CurvedDivider color="rgb(15 23 42 / 0.5)" flip={true} data-sentry-element="CurvedDivider" data-sentry-source-file="index.tsx" />
                    </div>
                    <div className="container mx-auto max-w-7xl text-center">
                        <motion.h2 className="text-4xl font-bold mb-6 text-white" initial={{
            opacity: 0,
            y: 20
          }} whileInView={{
            opacity: 1,
            y: 0
          }} viewport={{
            once: true
          }} transition={{
            duration: 0.8
          }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                            Ready to Bring Your Imagination to Life?
                        </motion.h2>
                        <motion.p className="text-xl mb-8 max-w-2xl mx-auto text-gray-300" initial={{
            opacity: 0,
            y: 20
          }} whileInView={{
            opacity: 1,
            y: 0
          }} viewport={{
            once: true
          }} transition={{
            duration: 0.8,
            delay: 0.2
          }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                            Join thousands of creators who are already using
                            CharGen to create stunning fantasy artwork, manage
                            game resources, and optimize their content for free.
                        </motion.p>
                        <motion.div initial={{
            opacity: 0,
            y: 20
          }} whileInView={{
            opacity: 1,
            y: 0
          }} viewport={{
            once: true
          }} transition={{
            duration: 0.8,
            delay: 0.4
          }} whileHover={{
            scale: 1.05
          }} whileTap={{
            scale: 0.95
          }} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                            <Link href="/generate" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                                <StyledHeroButton size="lg" data-sentry-element="StyledHeroButton" data-sentry-source-file="index.tsx">
                                    Start Creating for Free Now!
                                </StyledHeroButton>
                            </Link>
                        </motion.div>
                    </div>
                </section>
            </main>
        </div>;
}